import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private firebaseAnalytics: FirebaseAnalytics) { }

  logEvent(name: string, data?: {}) {
    // console.log(`tracked event`);
    // console.log(name);
    // console.log(data);
    this.firebaseAnalytics.logEvent(name, data);
  }

  initUser(result: firebase.User) {
    if (!result)
      return;

    this.firebaseAnalytics.setUserProperty('email', result.email);
    this.firebaseAnalytics.setUserProperty('name', result.displayName);
    this.firebaseAnalytics.setUserProperty('created', result.metadata.creationTime);
    this.firebaseAnalytics.setUserProperty('last_login', result.metadata.lastSignInTime);
  }
}
