import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoginPage } from './login.page';
import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';

export function openTos() { 
  console.log('trying to open tos');
  window.open('https://jamesonsaunders.com/tos', '_system');
}
export function openPrivacy() { 
  console.log('trying to open privacy');
  window.open('https://jamesonsaunders.com/privacy', '_system');
}

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: openTos, 
  privacyPolicyUrl: openPrivacy, 
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};

const routes: Routes = [
  {
    path: '',
    component: LoginPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
  ],
  declarations: [LoginPage],
  entryComponents: [LoginPage],
})
export class LoginPageModule {}
