import { Component, OnInit } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AnalyticsService } from 'src/app/providers/analytics/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  constructor(private keyboard: Keyboard, private analyticsService: AnalyticsService,) { }

  ngOnInit() {
    this.keyboard.hideFormAccessoryBar(false);
    this.analyticsService.logEvent('page login');
  }

}
